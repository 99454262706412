import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useStore } from 'react-redux';
import { throttle, delay, random } from 'underscore';

import { subscribe, unsubscribe } from 'bv-services/internal-event-bus';
import { handleMarketsAndOutcomes, withCancellation } from 'sportsbook-services';
import fetchCoupons from 'EventLevel/api/fetch_coupons';
import useTimer from 'EventLevel/hooks/use_timer';
import { getLastTimestamp } from 'EventLevel/selectors';
import subscribeToMarketSummary from 'EventLevel/services/subscribe_to_market_summary';
import subscribeToPeriod from 'EventLevel/services/subscribe_to_period';
import CouponsListView from './coupons_list_view';

const initialState = {
  fetching: true,
  coupons: [],
  eventId: null,
};

const CouponsListContainer = ({
  activeGroupId,
  refreshTime,
}) => {
  const { eventId } = useParams();
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const store = useStore();

  const prevMarketsIds = useRef([]);
  const prevOutcomesIds = useRef([]);
  const periodId = useRef(0);
  const lastTimestamp = useRef(new Date().getTime());

  // Effect to "reset" when the group changes
  useEffect(() => { setState(initialState); }, [eventId, activeGroupId]);

  // Fetch function which is used by timer and push updates
  const fetchData = withCancellation((canceled = { current: false }, extra = {}) => {
    const storeTimeStamp = getLastTimestamp(store.getState(), prevMarketsIds.current);
    // When this triggered from a marketsummary push,
    // the extra.timestamp will be filled from inside the pushed data
    // Need to make sure the last timestamp we got is used to fetch the data to bust caches.
    lastTimestamp.current = Math.max(lastTimestamp.current, extra.timestamp || 0, storeTimeStamp);
    fetchCoupons({
      eventId,
      groupId: activeGroupId,
      periodId: periodId.current,
      timestamp: lastTimestamp.current,
    })
      .then(({
        coupons,
        markets,
        outcomes,
      }) => {
        if (canceled.current) return;

        handleMarketsAndOutcomes(
          markets,
          outcomes,
          prevMarketsIds.current,
          prevOutcomesIds.current,
          dispatch,
        );

        prevMarketsIds.current = Object.keys(markets);
        prevOutcomesIds.current = Object.keys(outcomes);

        setState({
          fetching: false,
          coupons,
          eventId,
        });
      });
  });

  // Timer to fetch periodically
  useTimer(fetchData.execute, fetchData.cancel, refreshTime, [eventId, activeGroupId]);

  // Effect related to eventId, activeGroupId
  useEffect(() => {
    const throttledFetch = throttle(fetchData.execute, 1500, { leading: false });
    const delayedFetch = () => { delay(throttledFetch, random(0, 100)); };

    subscribe('refreshEventLevel', throttledFetch);
    const unsubscribeMarketSummary = subscribeToMarketSummary(
      dispatch,
      eventId,
      prevMarketsIds,
      throttledFetch,
    );

    const unsubscribePeriod = subscribeToPeriod(
      eventId,
      periodId,
      delayedFetch,
    );

    return () => {
      fetchData.cancel();

      unsubscribeMarketSummary();
      unsubscribePeriod();
      unsubscribe('refreshEventLevel', throttledFetch);
    };
  }, [eventId, activeGroupId]);

  // We want to keep outcomes/markets when switching coupon groups in case we can recycle them
  // However we want to remove them when the event changes
  useEffect(() => () => {
    if (state.eventId) {
      handleMarketsAndOutcomes(
        {},
        {},
        prevMarketsIds.current,
        prevOutcomesIds.current,
        dispatch,
      );
    }
  }, [eventId]);

  const { coupons, fetching } = state;

  return (
    <CouponsListView
      coupons={coupons}
      fetching={fetching}
    />
  );
};

CouponsListContainer.propTypes = {
  activeGroupId: PropTypes.number.isRequired,
  refreshTime: PropTypes.number.isRequired,
};

export default CouponsListContainer;
