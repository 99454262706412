import { locale } from 'bv-i18n';
import { v as bvVar } from 'bv';
import { getJSON } from 'bv-fetch';

export default ({ sportId, eventId }) => (
  getJSON(`/bv_event_level/${locale()}/${bvVar('brandId')}/coupon_groups/${sportId}`, {
    event_id: eventId,
  })
    .catch(() => [])
    .then((groups) => ({
      groups: groups.filter((g) => g.coupon_count > 0),
    }))
);
