import { v as bvVar } from 'bv';
import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

export default (eventId) => (
  getJSON(`/bv_api/events/${locale()}/${eventId}`, {
    brand_id: bvVar('brandId'),
  })
    .catch(() => null)
);
