import { isEqual, property, propertyOf } from 'underscore';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { getMarkets } from 'sportsbook-markets-selector';
import { getOutcomes } from 'sportsbook-outcomes-selector';

const createEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
);

const getCoupon = (state, props) => props.coupon;

export const selectEventLevel = (state) => state.eventLevel || {};

export const makeGetCouponMarkets = ({ filterBoolean = true } = { filterBoolean: true }) => (
  createEqualSelector(
    [getCoupon, getMarkets],
    (coupon, markets) => {
      const couponMarkets = coupon.markets.map((m) => (
        markets[m?.id]
          ? { ...markets[m?.id], o: (m?.o?.map((outcome) => outcome?.id || outcome) || []) }
          : markets[m?.id]
      ));

      return filterBoolean ? couponMarkets.filter(Boolean) : couponMarkets;
    },
  )
);

export const makeGetCouponFirstMarketOutcomes = () => {
  const getCouponMarkets = makeGetCouponMarkets();
  return createEqualSelector(
    [getCouponMarkets, getOutcomes],
    (markets, outcomes) => (
      markets.length ? (
        markets[0].o.map((outcomeId) => outcomes[outcomeId]).filter(Boolean)
      ) : []
    ),
  );
};

export const makeGetCouponMarketOutcomes = () => {
  const getCouponMarkets = makeGetCouponMarkets();
  return createEqualSelector(
    [getCouponMarkets, getOutcomes],
    (markets, outcomes) => (
      markets.reduce((marketOutcomes, market) => ({
        ...marketOutcomes,
        [market.id]: market.o.map((outcomeId) => outcomes[outcomeId]).filter(Boolean),
      }), {})
    ),
  );
};

export const makeGetCouponMarketOutcomesFlattened = () => {
  const getCouponMarkets = makeGetCouponMarkets();
  return createEqualSelector(
    [getCouponMarkets, getOutcomes],
    (markets, outcomes) => (
      markets.reduce((marketOutcomes, market) => (
        [
          ...marketOutcomes,
          ...market.o.map((outcomeId) => outcomes[outcomeId]).filter(Boolean)]
      ), [])
    ),
  );
};

const getMarketsIds = (state, marketsIds) => marketsIds;

export const getLastTimestamp = createEqualSelector(
  [getMarketsIds, getMarkets],
  (marketsIds, markets) => {
    const timestamps = marketsIds
      .map(propertyOf(markets))
      .map(property('timestamp'));

    return timestamps.length ? Math.max(...timestamps) : new Date().getTime();
  },
);
