import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import { Link } from 'bv-components';
import classnames from 'classnames';
import { v as bvVar } from 'bv';
import { getScoreboard } from 'SportsbookComponents/scoreboard/selectors';

const getPath = (inPlay, event, sportId, sportGroupId) => (
  inPlay
    ? `/live/${sportGroupId ? `group/${sportGroupId}` : event.sport_id}`
    : `/sports/${sportId}/meetings/${event.event_path_tree.id}/all`
);

const classNameMapping = {
  default: '',
  1: 'is-alternative',
  2: 'is-alternative',
  7: 'is-main',
  17: 'is-main',
  19: 'is-alternative',
};

const SportIcon = ({ event, sportId, sportGroupId }) => {
  const scoreboard = useSelector(getScoreboard, isEqual);
  const inPlay = scoreboard?.inPlay || event.in_play;
  const className = classnames(`bvs-icon is-sport-${event.sport_id} is-big event-level-header__sport-icon`, {
    'is-live': inPlay,
  }, (() => classNameMapping[bvVar('brandId')] || classNameMapping.default)());

  return (
    <Link
      className={className}
      to={getPath(inPlay, event, sportId, sportGroupId)}
    />
  );
};

SportIcon.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  sportId: PropTypes.string.isRequired,
  sportGroupId: PropTypes.number.isRequired,
};

export default SportIcon;
