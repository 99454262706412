import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'bv-hooks';
import { Spinner } from 'bv-components';
import { setPrerenderReady } from 'bv-services/seo';

import fetchEvent from './api/fetch_event';
import setMetaInfo from './services/set_sport_meta_info';
import NoEvents from './components/no_events';
import EventLevelView from './event_level_view';
import Context from './context';

const EventLevelContainer = () => {
  const {
    sportId,
    meetingId,
    eventId,
    marketGroupId,
  } = useParams();

  // Effect to fetch the event
  const [event, loading] = useFetch(
    () => fetchEvent(eventId),
    [eventId],
  );

  const contextValue = useMemo(() => ({ event }), [event]);

  // Effect to set the meta info
  useEffect(() => {
    if (event) {
      setMetaInfo(event, sportId);
      setPrerenderReady();
    }
  }, [event, marketGroupId]);

  if (loading) return <Spinner />;
  if (!event || !event.id) return <NoEvents />;

  return (
    <Context.Provider value={contextValue}>
      <EventLevelView
        event={event}
        eventId={eventId}
        meetingId={meetingId}
        sportId={sportId}
      />
    </Context.Provider>
  );
};

export default EventLevelContainer;
