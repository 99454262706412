import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  useParams, useHistory, Redirect, useLocation,
} from 'react-router-dom';
import { t } from 'bv-i18n';
import { SliderNav } from 'sportsbook-components';

import NoMarkets from 'EventLevel/components/no_markets';
import fetchCouponGroups from 'EventLevel/api/fetch_coupon_groups';
import useTimer from 'EventLevel/hooks/use_timer';
import Context from 'EventLevel/context';
import { useEventBetsCount } from 'SharedComponents/my_bets/hooks';

const couponsUrl = (sportPathId, meetingId, eventId, groupId) => (
  `/sports/${sportPathId}/meetings/${meetingId}/events/${eventId}${groupId ? `?market_group=${groupId}` : ''}`
);

const CouponGroupsContainer = ({
  refreshTime,
  activeGroupId,
}) => {
  const { eventId, meetingId, sportId: sportPathId } = useParams();
  const { sport_id: sportId } = useContext(Context).event;
  const eventBetsCount = useEventBetsCount(eventId);
  const { search } = useLocation();

  const [state, setState] = useState({
    fetching: true,
    couponGroups: [],
  });

  const history = useHistory();

  useTimer(() => {
    fetchCouponGroups({
      sportId,
      eventId,
    })
      .then(({ groups }) => {
        setState({
          couponGroups: groups,
          fetching: false,
        });
      });
  }, null, refreshTime, [eventId, sportId]);

  const { couponGroups, fetching } = state;

  const openBetsGroupItem = {
    id: 'open_bets',
    badge: eventBetsCount,
    icon: '',
    name: t('javascript.cashout.event_level.open_bets'),
  };

  const groups = eventBetsCount ? [openBetsGroupItem, ...couponGroups] : couponGroups;

  if (fetching) return null;
  if (!groups.length) return <NoMarkets />;

  // If there is a group in the URL but that group does not have coupons
  // Or no group in URL (first group is fetched, 0) but first coupon group does not have coupons
  // Redirect to first with coupons
  if (!activeGroupId || !groups.some((group) => group.id === activeGroupId)) {
    const newIndex = eventBetsCount && couponGroups.length ? 1 : 0;
    const searchParams = new URLSearchParams(search);
    searchParams.set('market_group', groups[newIndex].id);
    const searchParamsString = searchParams.toString();
    return (
      <Redirect
        to={{
          pathname: couponsUrl(sportPathId, meetingId, eventId),
          search: searchParamsString,
        }}
      />
    );
  }

  if (!eventBetsCount && couponGroups.length < 2) return null;

  // I can't use the Link component inside SliderNav because  button card styles are added
  // as flex-grow: 1, so if wrapped it won't work, add the flex-grow to the Link
  const navigateTo = (group) => () => {
    history.push(couponsUrl(sportPathId, meetingId, eventId, group.id));
  };

  return (
    <SliderNav
      elements={groups.map((group, i) => ({
        icon: group.icon,
        label: group.name,
        active: (group.id === activeGroupId) || (i === 0 && activeGroupId === 0),
        onClick: navigateTo(group),
        badge: group.badge,
      }))}
    />
  );
};

CouponGroupsContainer.propTypes = {
  refreshTime: PropTypes.number.isRequired,
  activeGroupId: PropTypes.number.isRequired,
};

export default CouponGroupsContainer;
