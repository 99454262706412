import { useContext } from 'react';
import PropTypes from 'prop-types';
import { SeoTitle } from 'bv-components';
import { ScoreboardContext } from 'bv-contexts';
import SportIcon from './sport_icon';

const EventHeader = ({ event }) => {
  const { setHeaderRef } = useContext(ScoreboardContext);
  const title = (event.event_path_tree && event.event_path_tree.description) || event.description;

  return (
    <div className="site-header-wrapper">
      <SportIcon event={event} sportId={event.sport_id} sportGroupId={event.sport_group_id} />
      <SeoTitle tag="h1" defaultHeading={title} />
      <div ref={setHeaderRef} className="event-level-header__icons" />
    </div>
  );
};

EventHeader.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
};

export default EventHeader;
