import { useEffect } from 'react';

export default (timerFunction, unmountFn, interval, dependencies) => {
  useEffect(() => {
    timerFunction();

    const timerID = window.setInterval(timerFunction, interval);

    return () => {
      window.clearInterval(timerID);
      if (unmountFn) unmountFn();
    };
  }, dependencies);
};
