import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import CouponGroups from 'EventLevel/components/coupon_groups';
import CouponsList from 'EventLevel/components/coupons_list';
import { OpenBets } from 'SharedComponents/my_bets/components';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const MarketsContainer = () => {
  const { eventId } = useParams();
  const searchParams = useQuery();
  const marketGroupId = searchParams.get('market_group');
  const parsedMarketGroupId = parseInt(marketGroupId, 10);
  const activeGroupId = Number.isNaN(parsedMarketGroupId)
    ? marketGroupId || 0 : parsedMarketGroupId;

  return (
    <>
      <CouponGroups
        activeGroupId={activeGroupId}
        refreshTime="30000"
      />
      {
        marketGroupId === 'open_bets' ? <OpenBets eventId={eventId} /> : (
          <CouponsList
            activeGroupId={activeGroupId}
            refreshTime="60000"
          />
        )
      }
    </>
  );
};

export default MarketsContainer;
