import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ScoreboardContext } from 'bv-contexts';
import Head from 'Seo/components/head';
import withLazyLoad from 'bv-lazy-load';
import Scoreboard from 'scoreboard';
import EventHeader from './components/event_header';
import Markets from './components/markets';
import JsonLd from './json_ld';

const EventComponents = withLazyLoad()('eventComponents');

const EventLevelView = ({
  event,
  eventId,
  meetingId,
  sportId,
}) => {
  const [headerRef, setHeaderRef] = useState(null);

  const value = useMemo(() => ({
    headerRef,
    setHeaderRef,
    showEventNavigator: true,
    americanFormat: event?.americanFormat,
  }), [headerRef, event?.americanFormat]);

  return (
    <ScoreboardContext.Provider value={value}>
      <Head>
        <JsonLd event={event} />
      </Head>
      <EventHeader event={event} />
      <Scoreboard
        event={event}
        sportPathId={sportId}
        meetingId={meetingId}
      />
      <EventComponents
        // Using event.sport_id here because sportId is the sport event path id
        sportId={event.sport_id}
        meetingId={meetingId}
        eventId={eventId}
      />
      <Markets />
    </ScoreboardContext.Provider>
  );
};

EventLevelView.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  eventId: PropTypes.string.isRequired,
  meetingId: PropTypes.string.isRequired,
  sportId: PropTypes.string.isRequired,
};

export default EventLevelView;
