import { Button } from 'bv-components';
import { t } from 'bv-i18n';
import { linkLocalizer } from 'bv-helpers/locale';

const NoEvents = () => (
  <div className="no-events-message" data-track="no-markets-list">
    <div className="no-events-message__icon" />
    <div className="no-events-message__text">
      <p>{t('empty_list')}</p>

      <Button href={linkLocalizer('/live/overview')} label={t('javascript.back_to_inplay')} primary />
    </div>
  </div>
);

export default NoEvents;
