import PropTypes from 'prop-types';
import { humanize, titleize, sprintf } from 'underscore.string';

const JsonLd = ({
  event: {
    home_name: homeName,
    away_name: awayName,
    event_date: endDate,
    description,
    dimensions: {
      COMPETITION,
    },
  },
}) => {
  const competition = humanize(COMPETITION);
  const humanDescription = sprintf(titleize(`${homeName} %s ${awayName} ${competition}`), 'v');
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'SportsEvent',
    name: description,
    description: humanDescription,
    startDate: new Date(endDate).toISOString(),
    competitor: [
      {
        '@type': 'SportsTeam',
        name: homeName,
      },
      {
        '@type': 'SportsTeam',
        name: awayName,
      },
    ],
  };

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line risxss/catch-potential-xss-react
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(structuredData),
      }}
    />
  );
};

JsonLd.propTypes = {
  event: PropTypes.shape({
    home_name: PropTypes.string.isRequired,
    away_name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    event_date: PropTypes.string.isRequired,
    dimensions: PropTypes.shape({
      COMPETITION: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default JsonLd;
