import { setMetaInfo } from 'bv-services/seo';
import { locale } from 'bv-i18n';

export default (event, sportId) => {
  setMetaInfo(() => {
    if (!event.id) return null;

    const date = event && event.event_date && new Date(event.event_date);

    return ({
      id: sportId,
      type: 'pre_event',
      data: {
        ...event,
        meeting_name: event && event.event_path_tree && event.event_path_tree.description,
        str_date: date && date.toLocaleDateString(locale(), {
          weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',
        }),
        time: date && date.toLocaleTimeString(locale(), { timeStyle: 'short' }),
      },
    });
  });
};
