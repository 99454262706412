import { registerHandler, unregisterHandler } from 'event-bus';

export default (
  eventId,
  periodIdRef,
  fetchCoupons,
) => {
  const handlePeriodUpdate = (err, { body: { periodId } }) => {
    if (periodIdRef.current !== periodId) {
      // eslint-disable-next-line no-param-reassign
      periodIdRef.current = periodId;
      fetchCoupons();
    }
  };

  const address = `/eventservice/event/${eventId}`;

  registerHandler(address, handlePeriodUpdate);
  return () => { unregisterHandler(address, handlePeriodUpdate); };
};
