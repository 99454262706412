import { memo } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';

import couponFactory from 'EventLevel/services/coupon_factory';

// Memo is not using a custom equality function so when polling it will re-render
// The purpose is to avoid re-renders when exact some props are received
// Due to the way useEffect and setState works in the parent, when group id changes
// a new group id prop is received by the container, this leads to the new fetch
// but also to the view to be rendered once with the previous data
// This also happens when using react/redux but mapStateToProps memoises by default
const CouponsListView = memo(({
  coupons,
  fetching,
}) => {
  if (!coupons || coupons.length === 0) {
    return fetching ? (
      <div className="market-list-wrapper loading">
        <Spinner wrapped={false} />
      </div>
    ) : null;
  }

  return (
    <div className="market-list-wrapper">
      {coupons.map(couponFactory)}
    </div>
  );
});

CouponsListView.propTypes = {
  coupons: PropTypes.instanceOf(Array).isRequired,
  fetching: PropTypes.bool.isRequired,
};

CouponsListView.displayName = 'CouponsListView';

export default CouponsListView;
