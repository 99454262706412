export const BetUpdateType = {
  NEW: 'NEW',
  SETTLED: 'SETTLED',
  UPDATED: 'UPDATED',
};

export const BetAction = {
  FULL_CASHOUT: 'FULL_CASHOUT',
  PARTIAL_CASHOUT: 'PARTIAL_CASHOUT',
};

export const BetSource = {
  LUCKY_DIP: 'LuckyDip',
};
