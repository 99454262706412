export default (coupons) => (
  coupons.map((coupon) => ({
    ...coupon,
    markets: coupon.markets.map((market) => {
      if (!market) return market;

      return {
        ...market,
        o: market.o.filter(Boolean).map((outcome) => ({
          ...outcome,
          prd: outcome.pr,
          pr: outcome.prF,
        })),
      };
    }),
  }))
);
