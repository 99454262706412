import { omit } from 'underscore';
import { registerHandler, unregisterHandler } from 'event-bus';
import { updateMarket } from 'sportsbook-markets-duck';

export default (
  dispatch,
  eventId,
  marketsIdsRef,
  fetchCoupons,
) => {
  const handleMarketUpdate = (err, { body: { u: updatedData, n: newData } }) => {
    const pushedMarkets = [
      ...(updatedData || []),
      ...(newData || []),
    ];

    pushedMarkets.forEach((delta) => {
      // Validate if market is known by event level
      if (!marketsIdsRef.current.includes(delta.id)) return;

      // We update all except the outcomes, they will be updated on fetch
      // Checking u and n because sometimes n is received but market already known
      dispatch(updateMarket(delta.id, omit(delta, 'o')));
    });

    const lastTimestamp = Math.max(...pushedMarkets.map((m) => m.timestamp));

    // If there is new data (not sure about this one)
    // Or updated status is different to 1, 2 (not just about a status change)
    // Or something happening with outcomes
    if (newData || (updatedData && updatedData.some(({ o: outcomes, st: status }) => (
      outcomes || ![1, 2].includes(status)
    )))) {
      fetchCoupons({ timestamp: lastTimestamp });
    }
  };

  const address = `/event/${eventId}/marketsummary`;

  registerHandler(address, handleMarketUpdate);
  return () => { unregisterHandler(address, handleMarketUpdate); };
};
