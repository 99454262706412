import withSuspense from 'bv-with-suspense';

const ListCoupon = withSuspense(() => import('./list_coupon_component'));
const SingleMarket1x3Coupon = withSuspense(() => import('./single_market_1x3_coupon_component'));
const ColumnLabeledNx2Coupon = withSuspense(() => import('./column_labeled_nx2_coupon_component'));
const OneColumnLimitedCoupon = withSuspense(() => import('./one_column_limited_coupon_component'));
const MovingLines2x3Coupon = withSuspense(() => import('./moving_lines_2x3_coupon_component'));
const MovingLines3x3Coupon = withSuspense(() => import('./moving_lines_3x3_coupon_component'));
const PiuCoupon = withSuspense(() => import('./piu_coupon_component'));
const ScorecastCoupon = withSuspense(() => import('./scorecast_component'));
const OutcomeLabeledNx2Coupon = withSuspense(() => import('./outcome_labeled_nx2_coupon_component'));
const OutcomeLabeledNx3Coupon = withSuspense(() => import('./outcome_labeled_nx3_coupon_component'));
const CorrectScoreCoupon = withSuspense(() => import('./correct_score_components'));
const BetboostCoupon = withSuspense(() => import('./betboost_component'));
const BetBuilder2Coupon = withSuspense(() => import('./betbuilder'));

export {
  ListCoupon,
  SingleMarket1x3Coupon,
  ColumnLabeledNx2Coupon,
  OneColumnLimitedCoupon,
  MovingLines2x3Coupon,
  MovingLines3x3Coupon,
  PiuCoupon,
  ScorecastCoupon,
  OutcomeLabeledNx2Coupon,
  OutcomeLabeledNx3Coupon,
  CorrectScoreCoupon,
  BetboostCoupon,
  BetBuilder2Coupon,
};
