import { accountId } from 'bv-helpers/session';
import { registerHandler, unregisterHandler } from 'event-bus';

// Subscribe a collection of bets to the push endpoint that receives the notifications for
// - New Bets
// - Bet removal
// In fact the Bets collection has the account number those bets belong to
// and what we do is to subscribe
// to a single endpoint with that account number, when a notification arrives,
// we execute the correct updater
// given the type of message we receive and providing the collection of bets
// wrapper over standard bus handler

export const subscribeToBets = (callback) => {
  const eventBusEndPoint = `/account-bets/${accountId()}`;

  const onUpdate = (err, { body }) => {
    callback(body);
  };

  registerHandler(eventBusEndPoint, onUpdate);

  return () => unregisterHandler(eventBusEndPoint, onUpdate);
};
