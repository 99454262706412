import { useState, useEffect } from 'react';
import { useLoggedIn } from 'bv-hooks';
import { fetchBetsCount } from '../api';
import { subscribeToEventBets } from '../subscribers';
import { BetUpdateType } from '../constants';

export const useEventBetsCount = (eventId) => {
  const [count, setCount] = useState(0);
  const loggedIn = useLoggedIn();

  const fetchCount = () => fetchBetsCount(eventId).then((response) => setCount(response.count));

  useEffect(() => {
    if (loggedIn) {
      fetchCount();
    }
  }, [eventId, loggedIn]);

  useEffect(() => {
    if (!loggedIn) return undefined;

    return subscribeToEventBets(parseInt(eventId, 10), {
      [BetUpdateType.NEW]: () => setCount(count + 1),
      [BetUpdateType.SETTLED]: () => setCount(count - 1),
    });
  }, [count, eventId, loggedIn]);

  return count;
};
