import 'public-path';
import { combineReducers } from 'redux';

import EventLevelLayout from './event_level_layout';
import betBuilder2Reducer from './components/coupons/betbuilder/duck';

const { addReducers } = window.reduxState;

addReducers({
  eventLevel: combineReducers({
    betBuilder2: betBuilder2Reducer,
  }),
});

export default EventLevelLayout;
