import { ContentLayout } from 'bv-components';
import EventLevelContainer from './event_level_container';

const EventLevelLayout = () => (
  <ContentLayout>
    <div className="event-level-view">
      <EventLevelContainer />
    </div>
  </ContentLayout>
);

export default EventLevelLayout;
