import { locale } from 'bv-i18n';
import { v as bvVar } from 'bv';
import { getJSON } from 'bv-fetch';
import normalizeCoupons from 'EventLevel/normalizers/coupons';
import presentCoupons from 'EventLevel/presenters/present_coupons';

let lastTimestamp = 0;

export default ({
  eventId,
  groupId,
  periodId,
  timestamp,
}) => {
  // This method us used with throttle so could end up being called twice
  // in 2 separate times in 3 seconds and the second run after the delay
  // has the params of the first trigger. To avoid fetching with the same timestamp
  // twice and getting stale data, when this happens the timestamp gets bumped
  lastTimestamp = timestamp === lastTimestamp ? timestamp + 1 : timestamp;
  return getJSON(`/bv_event_level/${locale()}/${bvVar('brandId')}/coupons/${eventId}/${groupId}`, {
    t: lastTimestamp,
    period_id: periodId,
  })
    .catch(() => [])
    .then(presentCoupons)
    .then(normalizeCoupons);
};
