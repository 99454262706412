import { normalize } from 'normalizr';
import coupon from './entities/coupon';

export default (rawCoupons) => {
  const {
    entities: {
      markets = {},
      outcomes = {},
    },
  } = normalize(rawCoupons, [coupon]);

  return {
    coupons: Object.values(rawCoupons),
    markets,
    outcomes,
  };
};
